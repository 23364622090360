<template>
  <nav>
    <ul class="flex items-center justify-center flex-1 max-w-full font-medium text-[#141933] gap-4 navbar font-serif">
      <li class="px-1">
        <UPopover :popper="{ strategy: 'absolute' }" :ui="{ container: 'w-max' }" mode="hover">
          <button type="button" class="flex gap-1 items-center hover:underline">Product
            <Icon name="majesticons:chevron-down-line" />
          </button>
          <template #panel>
            <div class="flex gap-4">
              <div class="bg-gray-50 p-4">
                <span class="font-bold">Pathway Framework</span>
                <ul class="flex flex-col">
                  <li><nuxt-link to="/products?tab=live-data-framework"
                      class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                      <Icon name="heroicons:cpu-chip-16-solid" />What is Pathway
                    </nuxt-link></li>
                  <li><nuxt-link to="/pricing" class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                      <Icon name="heroicons:currency-dollar-solid" /> Features & Pricing
                    </nuxt-link></li>
                </ul>
              </div>
              <div class="p-4 pl-0">
                <span class="font-bold">Choose your deployment</span>
                <ul class="flex flex-col">
                  <li><nuxt-link to="/developers/user-guide/deployment/docker-deployment"
                      class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                      <Icon name="fluent:chevron-circle-right-12-filled" />
                      Local
                    </nuxt-link></li>
                  <li><nuxt-link to="/developers/user-guide/deployment/cloud-deployment"
                      class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                      <Icon name="fluent:chevron-circle-right-12-filled" />
                      Cloud options
                      <div class="flex gap-2 items-center text-gray-500 ml-2 rounded-full">
                        <Icon name="mdi:aws" class="text-xl" />
                        <Icon name="teenyicons:azure-solid" />
                      </div>
                    </nuxt-link></li>
                </ul>
              </div>
            </div>
          </template>
        </UPopover>
      </li>
      <li class="px-1">
        <UPopover :popper="{ strategy: 'absolute' }" :ui="{ container: 'w-max' }" mode="hover">
          <button type="button" class="flex gap-1 items-center hover:underline">
            Solutions
            <Icon name="majesticons:chevron-down-line" />
          </button>
          <template #panel>
            <div class="flex gap-4">
              <ul v-for="(solution, title) in solutions" class="p-4">
                <template v-if="title === 'By topic'">
                  <nuxt-link to="/solutions" class="flex leading-5 hover:bg-gray-100 p-2 rounded-lg w-full ">
                    <span class="font-bold flex gap-2 items-center">
                      <Icon name="fluent:chevron-circle-right-12-filled" />Overview
                    </span>
                  </nuxt-link>
                  <span class="font-bold mb-2 block">By topic</span>
                </template>
                <span v-else class="font-bold mb-2 block">{{ title }}</span>
                <li v-for="item in solution">
                  <nuxt-link :to="item.path" class="leading-5 hover:bg-gray-100 p-2 rounded-lg w-full block">
                    <div class="flex gap-2">
                      <span class="p-2 w-10 h-10 rounded-lg flex items-center justify-center"
                        :class="{ 'bg-primary-500': title === 'ETL Pipelines', 'bg-secondary-500': title === 'RAG and LLM Pipelines' }">
                        <Icon :name="item.icon" class="text-white" />
                      </span>
                      <div>
                        <span class="font-bold">{{ item.title }}</span>
                        <span class="text-sm text-gray-500 max-w-xs whitespace-normal block font-sans">{{
                          item.description
                          }}</span>
                      </div>
                    </div>
                  </nuxt-link>
                </li>
              </ul>
              <div class="w-56 bg-gray-100 p-4">
                <nuxt-link to="/success-stories" class="hover:bg-gray-300 p-2 rounded-lg flex flex-col w-full">
                  <span class="font-bold mb-2 block">Success Stories</span>
                  <span class="text-gray-500 text-sm whitespace-normal font-sans">How major organizations improve
                    decision-making
                    based on always
                    up-to-date
                    knowledge</span>
                  <span class=" font-bold flex gap-2 items-center mt-4">
                    <Icon name="heroicons:trophy-16-solid" class="text-gray-600" />
                    Learn more
                  </span>
                </nuxt-link>
              </div>
            </div>
          </template>
        </UPopover>
      </li>

      <li class="px-1 hover:underline">
        <nuxt-link to="/blog">News</nuxt-link>
      </li>
      <li class="px-1">
        <UPopover :popper="{ strategy: 'absolute' }" :ui="{ container: 'w-max' }" mode="hover">
          <button type="button" class="flex gap-1 items-center hover:underline">
            Learn
            <Icon name="majesticons:chevron-down-line" />
          </button>
          <template #panel>
            <div class="p-4 ">
              <ul class="flex flex-col">
                <li><nuxt-link to="/bootcamps/rag-and-llms"
                    class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="heroicons:academic-cap-solid" class="text-gray-600" />
                    Free LLM Bootcamp
                  </nuxt-link></li>
                <li><nuxt-link to="/developers/user-guide/development/get-help" target="_blank"
                    class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="heroicons:wrench-screwdriver-16-solid" class="text-gray-600" /> Help and troubleshooting
                  </nuxt-link></li>
                <li><nuxt-link to="/blog?tag=engineering"
                    class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="heroicons:code-bracket-square-16-solid" class="text-gray-600" /> Developer blog
                  </nuxt-link></li>
              </ul>
            </div>
          </template>
        </UPopover>
      </li>

      <li class="px-1">
        <UPopover :popper="{ strategy: 'absolute' }" :ui="{ container: 'w-max' }" mode="hover">
          <button type="button" class="flex gap-1 items-center hover:underline">
            About
            <Icon name="majesticons:chevron-down-line" />
          </button>
          <template #panel>
            <div class="p-4 ">
              <ul class="flex flex-col">
                <li><nuxt-link to="/team" class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="heroicons:user-group-16-solid" class="text-gray-600" />
                    Team
                  </nuxt-link></li>
                <li><nuxt-link to="/events" class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="heroicons:calendar-date-range-16-solid" class="text-gray-600" /> Events
                  </nuxt-link></li>
                <li><nuxt-link to="/developers/user-guide/development/changelog"
                    class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="heroicons:archive-box-solid" class="text-gray-600" /> Product changelog
                  </nuxt-link></li>
              </ul>
            </div>
          </template>
        </UPopover>
      </li>

      <li class="px-1">
        <UPopover :popper="{ strategy: 'absolute' }" :ui="{ container: 'w-max' }" mode="hover">
          <button type="button" class="flex gap-1 items-center hover:underline">
            Get Started
            <Icon name="majesticons:chevron-down-line" />
          </button>
          <template #panel>
            <div class="p-4 ">
              <ul class="flex flex-col">
                <li><nuxt-link to="/developers/user-guide/introduction/welcome" target="_blank"
                    class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="heroicons:book-open-solid" class="text-gray-600" /> Developer documentation
                  </nuxt-link></li>
                <li><nuxt-link to="/developers/user-guide/introduction/installation" target="_blank"
                    class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="heroicons:document-arrow-down-16-solid" class="text-gray-600" /> Installation
                    Instructions
                  </nuxt-link></li>
                <li><nuxt-link to="/developers/templates" target="_blank"
                    class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md">
                    <Icon name="heroicons:bolt-20-solid" class="text-gray-600" />
                    Pathway Templates
                  </nuxt-link></li>
                <li><nuxt-link href="/get-license"
                    class="p-2 flex gap-2 items-center hover:bg-gray-200 w-full rounded-md mt-4 border font-bold bg-gray-100">
                    Get Free Enterprise Features
                  </nuxt-link></li>
              </ul>
            </div>
          </template>
        </UPopover>
      </li>
      <!-- <li class="px-1">
        <nuxt-link to="/developers/templates" class="hover:underline flex gap-1 items-center">
          <Icon name="heroicons:bolt-20-solid" class="text-gray-500" /> App Templates
        </nuxt-link>
      </li> -->
      <!-- 
      <li class="px-1 hover:underline">
        <nuxt-link to="/developers/user-guide/introduction/welcome" class="hover:underline flex gap-1 items-center">
          <Icon name="heroicons-outline:code" /> Docs
        </nuxt-link>
      </li> -->
    </ul>
  </nav>
</template>

<script setup lang="ts">
const { user } = await useAuth()
const solutions = {
  // "By topic": [
  //   {
  //     title: "Gen AI for the Enterprise",
  //     description: "Live data pipelines for AI apps, LLM-powered analytics",
  //     path: "/solutions/enterprise-generative-ai"
  //   }, {
  //     title: "AI RAG pipelines at scale",
  //     description: "Connect your documents & live data",
  //     path: "/solutions/rag-pipelines"
  //   }, {
  //     title: "ETL for Unstructured Data",
  //     description: "For AI use cases",
  //     path: "/solutions/etl-unstructured-data"
  //   }
  // ],
  "RAG and LLM Pipelines": [
    {
      title: "Document Answering",
      description: "High-accuracy Document Answering",
      path: "/solutions/document-answering",
      icon: "heroicons:clipboard-document-check-solid"
    },
    {
      title: "RAG Slides Search",
      description: "Accurate Slides Search",
      path: "/solutions/slides-ai-search",
      icon: "heroicons:document-magnifying-glass-solid"
    },
    {
      title: "Contract Management with AI",
      description: "Managing your legal and financial documents in enterprise",
      path: "/solutions/ai-contract-management",
      icon: "heroicons:document-text-solid"
    }
  ],
  "ETL Pipelines": [
    {
      title: "AI-enabled vehicles & eSports",
      description: "Telemetry tracking, Augmented reality, Realtime monitoring",
      path: "/solutions/ai-enabled-vehicles-n-esports",
      icon: "ion:car-sport"
    }, {
      title: "Logistics",
      description: "Postal Services, 3PL, Container Shipping",
      path: "/solutions/logistics",
      icon: "heroicons:truck-solid"
    }
  ]
}
</script>

<style scoped>
@media (max-width: 1315px) {
  .navbar {
    gap: 0.5rem;
  }
}

@media (max-width: 1025px) {
  .navbar {
    display: none;
  }
}

li {
  white-space: nowrap;
}
</style>
